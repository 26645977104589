


import { Component, Vue, Watch } from "vue-property-decorator";
import Axios from "axios";
import Questionnaire from "@/components/pos/questionnaire/Questionnaire.vue";
import {BASE_API_URL, EventBus} from "../../../config";
import stores from "../../../store/store";
const store: any = stores;
import { HollowDotsSpinner } from "epic-spinners";
import '@/vuebar';
import { mixins } from "vue-class-component";
import { VueEditor } from "vue2-editor";
import CommonMixin from "@/mixins/common.mixin";
import RadialBar from "@/views/charts/radial-bar/radial-bar";
import DisplayFiles from "@/views/DisplayDocument.vue";
import _ from "lodash";
import TextEditor from "@/views/TextEditor.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

const w = window as any;
const Vuebar = w.Vuebar as any;
Vue.use(Vuebar);
Component.registerHooks(["beforeRouteEnter"]);

@Component({
  components: { Questionnaire, HollowDotsSpinner, VueEditor, DisplayFiles, TextEditor }
})
export default class BorrowerDashboardComponent extends mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public borrowerData: any = null;
  public message = null;
  public fileToDisplay = null;
  public dotLoader = false;
  public loader = false;
  public customToolBar = [];
  public documents: any = [];
  public allComments = [];
  public processorName = [];
  public fileLoader = [];
  public notificationsCount = 0;
  public conditionType = "";
  public event: any = [];
  public eventLoader = null;
  public selectedProcessor = null;
  public user: any = {};
  public token: any = "";
  public brokerDisclosuresDocuments = [];
  public brokerDisclosuresDocumentsStatus = null;
  public additionalDocuments = [];
  public showBrokerDisclosuresDocuments: boolean = false;
  public fileForDelete = {};
  public fileInput = null;
  public visibleInputId:string = null;
  public enterTargetRow:object = null;

  get loanTxnId() {
    return this.$store.state.sessionObject &&
      this.$store.state.sessionObject.userInfo &&
      this.$store.state.sessionObject.userInfo.loanTxnId
      ? this.$store.state.sessionObject.userInfo.loanTxnId
      : null;
  }

  public get session() {
    return this.$store.state.sessionObject;
  }

  get eDisclosureAgreement() {
    return _.get(this.$store.state.sessionObject, 'pendingActions.eDisclosureAgreement', null);
  }

  /********************************************************************************************************************************
   *                                        get borrower's headerInformation                                                      *
   *                        (loanInformation, address, primaryBorrowerIndex, relationshipMapping, processorName)                  *
   *                        from borrower model, borrowerInfo model, loanAndProperty model                                        *
   ********************************************************************************************************************************/
  public async getBorrowerInfo() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "borrower/getBorrowerInfo",
        {
          loanTxnId: this.loanTxnId,
          userId: this.$userId
        }
      );

      if (response.status == 200) {
        this.borrowerData = response.data;
      }

      this.getProcessorName();
    } catch (error) {
      console.log(error);
    }
  }
  /******************************************************************************************************************************
   *                get assigned processors name and role                                                                          *
   ******************************************************************************************************************************/
  public getProcessorName() {
    if (this.borrowerData && this.borrowerData.processorName.length > 0) {
      this.processorName = this.borrowerData.processorName.filter(
        a => a.toUserType == "Wemlo Processor"
      );
    }
  }

  public addMessage(type) {
    this.sendMessage({
      toUserType: this.selectedProcessor.toUserType,
      loanTxnId: this.loanTxnId,
      toUserId:
        this.selectedProcessor != null
          ? this.selectedProcessor.processorId
          : null
    });
  }

  public async getNotification() {
    this.dotLoader = true;
    try {
      let response = await Axios.get(BASE_API_URL + "message/getNotification", {
        params: {
          userId: this.$userId
        }
      });
      this.notificationsCount = response.data.data.length;
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
      console.log(error);
    }
  }

  /*********************************************************************************************************************************
   *                         this function return Current User Name                                                                *
   *********************************************************************************************************************************/
  public getFullName() {
    if (this.borrowerData) {
      let borrower = this.borrowerData.borrowerInfo.find(
        a => a.userId == this.$userId
      );
      let name = borrower.personalInfo.name;
      let firstName = name.firstName;
      let lastName = name.lastName ? name.lastName : null;
      return (
        this.capitalizeFirstCharacter(firstName) +
        " " +
        this.capitalizeFirstCharacter(lastName)
      );
    }
  }

  public capitalizeFirstCharacter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /****************************DO NOT REMOVE*********************************** */
  // public getCountOfPendingQuestion() {
  //   let pendingList = store.state.posSessionConfig.pendingQuestionList;
  //   let arr = [];
  //   Object.keys(pendingList).forEach(a => {
  //     pendingList[a].forEach(list => {
  //       Object.keys(list).forEach(b => {
  //         if (typeof list[b] == "object") {
  //           arr = arr.concat(list[b]);
  //         }
  //       });
  //     });
  //   });
  //   this.pendingQuestion = arr.length > 0 ? arr.length : 18;
  // }
  /***************************************************************************** */

  public async getPendingDocuments() {
    this.loader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "borrower/getPendingDocuments",
        { loanTxnId: this.loanTxnId });
      this.documents = response.data.documents;
      this.allComments = response.data.comments;
      this.fileLoader = [];
      this.documents.forEach(f => {
        this.fileLoader.push(false);
      });
      this.loader = false;
    } catch (error) {
      console.log(error);
      this.loader = false;
    }
  }

  public checkDocumentHasSample(doc) {
    let returnValue = false;
    if (doc.samples.length > 0) {
      returnValue = true;
    }
    return returnValue;
  }

  public checkIfRejected(fileHistory) {
    let returnData = false;
    if (fileHistory.length > 0) {
      if (fileHistory[fileHistory.length - 1].status == "Rejected") {
        returnData = true;
      }
    }
    return returnData;
  }

  public checkDocumentHasRejectComment(doc) {
    let returnValue = false;
    if (doc.fileHistory.length > 0) {
      if (doc.fileHistory[doc.fileHistory.length - 1].commentPath != null) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  public displaySample(file) {
    this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](null, file);
  }

  public displayCommentModal(index, catName) {
    let comment = this.allComments[index].comment;
    let files = this.allComments[index].attachments;
    if (files.length > 0) {
      this.$refs.dfl["setInitialValuesForShowingSamples"](
        catName,
        files,
        comment
      );
    } else {
      this.$refs.dfl["setInitialValuesForShowMessage"](comment);
    }
  }

  public async uploadFile(event, id, index) {
    try {
      this.$set(this.fileLoader, index, true);
      if (event.target.files.length == 0) {
        return;
      }
      let formData = new FormData();
      Object.keys(event.target.files).forEach((e, i) => {
        formData.append("file", event.target.files[i]);
      });
      formData.append("id", id);
      formData.append("loanTxnId", this.loanTxnId);
      formData.append("userId", this.$userId);
      let response = await Axios.post(
        BASE_API_URL + "pos/uploadLoanDocument",
        formData);
      this.$set(this.fileLoader, index, false);
      await this.getPendingDocuments();
      this.$snotify.success("Document Uploaded Successfully");
    } catch (error) {
      this.$set(this.fileLoader, index, false);
      console.log(error);
    }
  }

  async getBorrowerLoanProgressValue() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "borrower/getBorrowerLoanProgressValue",
        {
          params: { loanTxnId: this.loanTxnId }
        }
      );
      RadialBar(response.data.progress, "of completion", "radialBar");
    } catch (error) {
      console.log(error);
    }
  }

  public deleteDoc(from) {
    try {
      this[from.deleteData].splice(from.index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  public showAttachedFiles(index) {
    this.$modal.show('toSeeAttachment', {
      type: 'outgoing',
      file: this.file,
      currentFileIndex: index,
    })
  }

  public showPendingConditions(name) {
    this.$modal.show("modalForPendingConditions");
    this.conditionType = name;
  }
  public displayDocuments(files){
    if (files[0].hasOwnProperty("path")) {
      this.$refs.dfl["setInitialValuesForShowingSamplesWithDeleteButton"](
        // "View Document",
        files, false,null
      );
    }
}
  /**************************************************************************************************
   *                                    IF DOCUMENT NOT LOADED THEN                                  *
   **************************************************************************************************/
  public ifDocumentNotLoad(time: number) {
    this.dotLoader = true;
    try {
      setTimeout(() => {
        let checkLoader = document.getElementById("checkLoader");
        if (checkLoader != null) {
          let iframe: any = document.getElementById("checkElement");
          if (iframe.localName == "iframe") {
            iframe.src =
              "https://docs.google.com/gview?url=" +
              this.fileToDisplay +
              "&embedded=true";
          } else {
            iframe.src = this.fileToDisplay;
          }
          this.ifDocumentNotLoad(time + 1000);
        }
      }, time);
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }

  /*************************GET THE CUSTOM TASK WHERE displayEventToBorrower attribute true************************************ */
  public async getCustomTask() {
    try {
      this.eventLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "borrower/getCustomTask",
        {
          loanTxnId: this.loanTxnId
        });
      this.event = response.data.customTask;
      this.eventLoader = false;
    } catch (error) {
      console.log(error);
      this.eventLoader = false;
    }
  }
  /****************************************************************************************************** */

  public async getWesignRequiredDocuments() {

    if (!this.eDisclosureAgreement) {
      this.brokerDisclosuresDocuments = [];
      this.additionalDocuments = [];
      this.brokerDisclosuresDocumentsStatus = '--signed';

      return;
    }

    const result = await Axios.get(
      `${BASE_API_URL}disclosure/borrowerWesignRequiredDocuments`,
      {
        params: { loanTxnId: this.loanTxnId }
      }
    );

    this.brokerDisclosuresDocuments = result.data.filter(document => ['Broker Disclosure'].includes(document.type));
    this.additionalDocuments = result.data.filter(document => ['Additional Disclosure', 'Lender Disclosure'].includes(document.type));
    this.additionalDocuments = this.additionalDocuments.filter(document => !document.allFieldsSigned);

    this.brokerDisclosuresDocumentsStatus =
      this.brokerDisclosuresDocuments.every(document => document.allFieldsSigned)
        ? '--signed'
        : this.brokerDisclosuresDocuments.some(document => this.currentBorrowerCoBorrowerSignPending(document))
          ? '--current-user-pending'
          : '--pending';

    if (this.brokerDisclosuresDocumentsStatus === '--signed') { this.brokerDisclosuresDocuments = []; }
  }

  public getWesignStatusLabel(doc) {

    try {
      if (doc.allFieldsSigned) { return 'Signed'; }
      if (this.currentBorrowerCoBorrowerSignPending(doc)) { return 'Your signature required'; }
      if (!this.currentBorrowerCoBorrowerSignPending(doc) && !doc.allFieldsSigned) { return 'Pending signatures'; }
    } catch (e) {
      return '';
    }

  }

  public toggleBrokerDisclosuresDocumentsVisibility() {
    this.showBrokerDisclosuresDocuments = !this.showBrokerDisclosuresDocuments;
  }

  public currentBorrowerCoBorrowerSignPending(document) {
    if (!this.borrowerData) {
      return;
    }

    const borrowerIds = [this.borrowerData.borrowerInfo[this.borrowerData.primaryBorrowerIndex].userId];
    const coBorrowerIndex = this.borrowerData.relationshipMapping[0].coBorrowerIndex[0];

    if (coBorrowerIndex) {
      borrowerIds.push(this.borrowerData.borrowerInfo[coBorrowerIndex].userId);
    }

    return document.allPendingSignUserIds.some(id => borrowerIds.includes(id));
  }


  public openFormsPage() {
    this.$router.push('/borrower/disclosures?id=' + this.loanTxnId);
  }

  public previewUploadFile(fileInfo) {
    let index = this.file.findIndex(f => f.name == fileInfo.name && f.size == fileInfo.size);
    if (index < 0 || this.isFileHasUploadError(this.file[index].name)) return;
    this.showAttachedFiles(index)
  }

  public isFileHasUploadError(fileName) {
    let fileInfo = this.file.find(f => f.name == fileName);
    if (!fileInfo) return;
    if (_.get(fileInfo, 'errorMessage')) return true;
  }

  setFileInput() {
    this.fileInput = document.documentElement.querySelector('.vue-file-agent .file-input');
  }

  public onPaperclipHandler() {
    if (!this.fileInput) {
      this.setFileInput();
    }

    this.fileInput?.click();
  }

  public setFileForDelete(file) {
    this.fileForDelete = file
  }

  public onDragToRowEnterHandler(e) {
    this.enterTargetRow = e.target;
    this.visibleInputId = e.currentTarget.id;
  }

  public onDragToRowLeaveHandler(e) {
    if (this.enterTargetRow === e.target) {
      this.visibleInputId = null;
    }
  }

  async created() {
    await this.getPendingDocuments();
  }

  async mounted() {
    this.setFileInput();
    EventBus.$on('file-for-delete', this.setFileForDelete)
    this.getBorrowerInfo();
    this.getCustomTask();
    this.getNotification();
    this.getWesignRequiredDocuments();
    if (this.$loader) {
      this.$loader.hide();
    }
    this.getBorrowerLoanProgressValue();
  }

  beforeDestroy() {
    EventBus.$off('file-for-delete', this.setFileForDelete)
  }
}
