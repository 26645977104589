import ApexCharts from 'apexcharts';

export default (progress: number, label: string, elem: string) => {
    var options = {
        chart: {
            height: 300,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                track: {
                    background: '#186181',
                },
                hollow: {
                    margin: 20,
                    size: "70%",
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: true,
                        fontSize: '15px',
                        fontFamily: 'Conv_Montserrat-Regular',
                        color: 'white',
                        offsetY: 40
                    },
                    value: {
                        show: true,
                        fontSize: '40px',
                        fontFamily: 'Conv_Montserrat-Regular',
                        color: 'white',
                        offsetY: -15,
                        formatter: function (val) {
                            return val + '%'
                        }
                    }
                }
            }
        },
        series: [progress],
        labels: [label],
        colors: ["#00b6ff"],
        /**
         * Handle chart's responsive
         */
        responsive: [
            {
                breakpoint: 1200,
                options: {
                    chart: {
                        width: 320,
                        height: 270,
                        marginRight: 15
                    }
                }
            },

            {
                breakpoint: 991,
                options: {
                    chart: {
                        width: 200,
                        height: 270,
                        marginRight: 15
                    }
                }
            }
        ]

    }

    var chart = new ApexCharts(
        document.querySelector(`#${elem}`),
        options
    );
    chart.render();
}